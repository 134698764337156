* {
    box-sizing: border-box;
    scroll-behavior: smooth;
}

body {
    margin: 0;
    padding: 0;
    background-image: url('https://res.cloudinary.com/dzwllpun3/image/upload/v1663270385/AM%20Studios/bgPattern_fi28qu.png');
}

img {
    max-width: 100%;
    display: block;
}

.navbar-menu-item {
    position: relative;
}

.navbar-menu-item:hover::before {
    content: '';
    display: block;
    width: 20px;
    height: 2px;
    background-color: #a82c1f;
    position: absolute;
    left: 0px;
    animation: appearLeft 0.1s ease-in;
}

@keyframes appearLeft {
    0% {
        left: -25px;
    }
    100% {
        left: 0px;
    }
}

.navbar-menu-item:hover::after {
    content: '';
    display: block;
    width: 20px;
    height: 2px;
    background-color: #a82c1f;
    position: absolute;
    right: 0px;
    animation: appearRight 0.1s ease-in;
}

@keyframes appearRight {
    0% {
        right: -25px;
    }
    100% {
        right: 0px;
    }
}


@tailwind base;
@tailwind components;
@tailwind utilities;